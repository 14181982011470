import React from 'react';
import { VStack, Box, Text, Divider, HStack, Flex } from '@chakra-ui/layout';
import { maskCpfOrCnpj, maskMoney, maskDate } from 'services/masks.service';
import { GetSaleDto } from '../../clients';

type ReceiptProps = {
	sale: GetSaleDto;
};

const Receipt: React.FC<ReceiptProps> = ({ sale }) => {
	const {
		amount,
		description,
		seller,
		original_amount,
		payment_day,
		installments,
		payer_name,
		payer_document,
		payer_phone,
		payment_type_description,
		celcoin,
	} = sale;
	const { address } = seller;

	const formatDate = (param) => {
		const date = new Date(param)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');

		return param
			? `${maskDate(date[0] ? date[0].split('-').reverse().join('-') : '-/-/-')} às ${date[1] ? date[1]?.substr(0, 5) : '-:-'}`
			: '-';
	};

	return (
		<VStack spacing={4}>
			<Flex flexDir={`column`} p={8} pt={0} bgColor={`#fff`} rounded={`md`} boxShadow={`md`} w='100%'>
				<Box>
					<Text fontSize='xl' color='primary' fontWeight='bold' mb={2} textAlign='center'>
						Emitente
					</Text>
				</Box>
				<Divider orientation={`horizontal`} mb={3} />
				<VStack>
					<HStack align='center'>
						<Text fontSize='md' fontWeight='bold' color='primary'>
							{seller?.name || ''}
						</Text>
					</HStack>
					<HStack align='center'>
						<Text fontSize='md' fontWeight='bold' color='primary'>
							CPF / CNPJ:
						</Text>
						<Text fontSize='md' color='primary'>
							{maskCpfOrCnpj(seller?.document) || ''}
						</Text>
					</HStack>
					<HStack align='center'>
						<Text fontSize='md' fontWeight='bold' color='primary'>
							Telefone:
						</Text>
						<Text fontSize='md' color='primary'>
							{seller?.phone || ''}
						</Text>
					</HStack>
					<HStack align='center'>
						<Text fontSize='md' fontWeight='bold' color='primary'>
							Endereço:
						</Text>
						<Text fontSize='md' color='primary'>
							{`${address?.street}, ${address?.number} - ${address?.city}, ${address?.state}` || ''}
						</Text>
					</HStack>
				</VStack>
			</Flex>

			<Divider orientation={`horizontal`} />

			<Flex flexDir={`column`} p={6} pt={2} bgColor={`#fff`} rounded={`md`} boxShadow={`md`} w='100%'>
				<Box display='flex' flexDir='row' justifyContent='space-between' alignContent='center' mb={3}>
					<Text fontSize='xl' color='primary' fontWeight='bold'>
						Intermediadora Financeira
					</Text>
					<Text fontSize='xl' color='primary' fontWeight='bold'>
						Usuário do Cartório
					</Text>
				</Box>
				<Divider orientation={`horizontal`} mb={5} />

				<HStack align='flex-start' justify='space-between' pb={2}>
					<VStack w='45%' align='flex-start'>
						<HStack>
							<Text fontSize='md' fontWeight='bold' color='primary'>
								Nome:
							</Text>
							<Text fontSize='md' color='primary'>
								Parcela Express Serviços Digitais SA
							</Text>
						</HStack>
						<HStack>
							<Text fontSize='md' fontWeight='bold' color='primary'>
								CPF / CNPJ:
							</Text>
							<Text fontSize='md' color='primary'>
								32.602.300/0001-37
							</Text>
						</HStack>
					</VStack>

					<VStack w='45%' align='flex-end'>
						{payer_name && (
							<HStack align='flex-start'>
								<Text fontSize='md' fontWeight='bold' color='primary'>
									Nome:
								</Text>
								<Text fontSize='md' color='primary' textAlign='right'>
									{payer_name || ''}
								</Text>
							</HStack>
						)}
						{payer_document && (
							<HStack align='center'>
								<Text fontSize='md' fontWeight='bold' color='primary'>
									CPF:
								</Text>
								<Text fontSize='md' color='primary'>
									{maskCpfOrCnpj(payer_document) || ''}
								</Text>
							</HStack>
						)}
						{payer_phone && (
							<HStack align='center'>
								<Text fontSize='md' fontWeight='bold' color='primary'>
									Telefone:
								</Text>
								<Text fontSize='md' color='primary'>
									{payer_phone || ''}
								</Text>
							</HStack>
						)}
					</VStack>
				</HStack>
			</Flex>

			<Divider orientation={`horizontal`} />

			<Flex flexDir={`column`} p={8} pt={2} bgColor={`#fff`} rounded={`md`} boxShadow={`md`} mt={5} w='100%'>
				<Box>
					<Text fontSize='xl' color='primary' fontWeight='bold' mb={3} textAlign='center'>
						Dados de Pagamento
					</Text>
					<Divider orientation={`horizontal`} mb={5} />
				</Box>
				<HStack spacing='10' alignItems='flex-start' justifyContent='space-between' color='primary' pb={2}>
					<Box>
						<Text fontWeight='bold'>N° de Parcelas</Text>
						<Text>
							{installments}x de R$ {maskMoney(original_amount / installments / 100)}
						</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Forma de Pagamento</Text>
						<Text>{payment_type_description ? payment_type_description : ''}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>N° do Pedido</Text>
						<Text>{description}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Data de Pagamento</Text>
						<Text>{formatDate(payment_day)}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Preço Total</Text>
						<Text>R$ {maskMoney(original_amount / 100)}</Text>
					</Box>
				</HStack>
			</Flex>

			<HStack w='80%' bg='defaultGrey' borderRadius='md'>
				<VStack justify='space-between' p={2} w='100%'>
					<Text fontSize='md' fontWeight='bold' textAlign='center' color='primary'>
						{celcoin ? 'Total do Título' : 'Total destinado ao cartório:'}
					</Text>
					<Text fontSize='md' color='primary'>
						R$ {maskMoney(amount / 100)}
					</Text>
				</VStack>
				<>
					<Divider orientation='vertical' />

					<VStack justify='space-between' p={2} w='100%'>
						<Text fontSize='md' fontWeight='bold' textAlign='center' color='primary'>
							{celcoin ? 'Valor Pago:' : 'Total a pagar com acréscimo:'}
						</Text>
						<Text fontSize='md' color='primary'>
							R$ {maskMoney(original_amount / 100)}
						</Text>
					</VStack>

					<Divider orientation='vertical' />

					<VStack justify='space-between' p={2} w='100%'>
						<Text fontSize='md' fontWeight='bold' textAlign='center' color='primary'>
							{celcoin ? 'Valor de Acréscimo:' : 'Custo financeiro da operação:'}
						</Text>
						<Text fontSize='md' color='primary'>
							R$ {maskMoney((original_amount - amount) / 100)}
						</Text>
					</VStack>
				</>
			</HStack>

			<VStack alignSelf='center' color='primary' w='95%' spacing={5}>
				<Text fontSize='md'>
					Pagamento processado pelo Parcela Express. Em caso de dúvida entre em contato pelo telefone (31) 3264-0337 ou consulte nossos
					termos de uso e política de privacidade em www.parcelaexpress.com.br
				</Text>
				<Text fontSize='md'>
					Através do presente instrumento, fica confirmada a operação financeira destinada ao pagamento de emolumentos e outras despesas
					relacionadas ao Cartório. O titular do cartão confirma a transação realizada e renuncia, de forma irretratável, ao direito de
					cancelar ou contestar a operação junto à operadora de cartão de crédito.
				</Text>
				<Text fontSize='sm'>
					Aviso: a operação aqui registrada não enseja repasse de custos. O usuário final, por livre e espontânea vontade, optou por efetuar
					o pagamento dos emolumentos e despesas por meio de pagamento eletrônico e eventuais custos adicionais daí decorrentes não têm
					qualquer relação com a unidade extrajudicial.
				</Text>
				<Text fontSize='sm'>
					A Parcela Express cumpre com a legislação e regulamentação em vigor e possui programa de integridade e políticas internas
					destinadas a assegurar o cumprimento da legislação aplicável. Todo e qualquer dado pessoal coletado neste recibo é tratado em
					conformidade com a legislação vigente e conforme a Política de Privacidade da Parcela Express disponível no nosso site.
				</Text>
				<Text alignSelf='flex-start' fontSize='md'>
					Assinatura do Cliente: ________________________________________
				</Text>
			</VStack>
		</VStack>
	);
};

export default Receipt;
