import React from 'react';
import { Flex, Stack, Heading, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import { Center } from '@chakra-ui/layout';
import { Formik } from 'formik';

import Loader from 'containers/Loader';

import {
	AcquirersApi,
	CostsPlansApi,
	CreateSalesPlanRequest,
	GetAcquirerDto,
	GetCostPlanDto,
	GetMerchantDto,
	GetSettlementPlanDto,
	MerchantsApi,
	SalesPlansApi,
	SettlementPlansApi,
} from '../../clients';
import Button from '../../components/Button';
import Paper from '../../containers/Paper';
import { getApiAuthConfig } from '../../services/api.service';
import PaymentTypeForm from './components/PaymentTypesForm';
import SalePlanForm from './components/SalePlanForm';
import createSalePlanSchema from './components/Validation';
import Breadcrumb from 'components/Breadcrumb';

const CreateSalePlan: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const settlementPlansApi = new SettlementPlansApi(apiConfig);
	const merchantsApi = new MerchantsApi(apiConfig);
	const acquirersApi = new AcquirersApi(apiConfig);
	const costsPlansApi = new CostsPlansApi(apiConfig);
	const salesPlansApi = new SalesPlansApi(apiConfig);

	const [isLoading, setIsLoading] = React.useState(false);
	const [merchants, setMerchants] = React.useState<GetMerchantDto[]>([]);
	const [acquirers, setAcquirers] = React.useState<GetAcquirerDto[]>([]);
	const [costPlans, setCostPlans] = React.useState<GetCostPlanDto[]>([]);
	const [settlementPlans, setSettlementPlans] = React.useState<GetSettlementPlanDto[]>([]);

	const { addToast } = useToasts();

	const createSalePlanInitialValues = {
		name: '',
		description: '',
		payment_types: [
			{
				code: 'debit',
				fee: 0.55,
				description: 'Débito',
				installments: 1,
				operation_mode: 'debit',
			},
			{
				code: 'pix',
				fee: 2.2,
				description: 'PIX',
				installments: 1,
				operation_mode: 'pix',
			},
			{
				code: 'pix_pos',
				fee: 2.2,
				description: 'Pix Pos',
				installments: 1,
				operation_mode: 'pix_pos',
			},
			{
				code: 'billet',
				fee: 2.5,
				description: 'Boleto',
				installments: 1,
				operation_mode: 'billet',
			},
			{
				code: 'credit1x',
				fee: 2.98,
				description: 'Crédito 1x',
				installments: 1,
				operation_mode: 'credit',
			},
			{
				code: 'credit2x',
				fee: 4.58,
				description: 'Crédito 2x',
				installments: 2,
				operation_mode: 'credit',
			},
			{
				code: 'credit3x',
				fee: 5.67,
				description: 'Crédito 3x',
				installments: 3,
				operation_mode: 'credit',
			},
			{
				code: 'credit4x',
				fee: 6.79,
				description: 'Crédito 4x',
				installments: 4,
				operation_mode: 'credit',
			},
			{
				code: 'credit5x',
				fee: 7.93,
				description: 'Crédito 5x',
				installments: 5,
				operation_mode: 'credit',
			},
			{
				code: 'credit6x',
				fee: 9.1,
				description: 'Crédito 6x',
				installments: 6,
				operation_mode: 'credit',
			},
			{
				code: 'credit7x',
				fee: 11.02,
				description: 'Crédito 7x',
				installments: 7,
				operation_mode: 'credit',
			},
			{
				code: 'credit8x',
				fee: 12.26,
				description: 'Crédito 8x',
				installments: 8,
				operation_mode: 'credit',
			},
			{
				code: 'credit9x',
				fee: 14.87,
				description: 'Crédito 9x',
				installments: 9,
				operation_mode: 'credit',
			},
			{
				code: 'credit10x',
				fee: 14.68,
				description: 'Crédito 10x',
				installments: 10,
				operation_mode: 'credit',
			},
			{
				code: 'credit11x',
				fee: 15.87,
				description: 'Crédito 11x',
				installments: 11,
				operation_mode: 'credit',
			},
			{
				code: 'credit12x',
				fee: 17.1,
				description: 'Crédito 12x',
				installments: 12,
				operation_mode: 'credit',
			},
			{
				code: 'credit13x',
				fee: 18.48,
				description: 'Crédito 13x',
				installments: 13,
				operation_mode: 'credit',
			},
			{
				code: 'credit14x',
				fee: 19.7,
				description: 'Crédito 14x',
				installments: 14,
				operation_mode: 'credit',
			},
			{
				code: 'credit15x',
				fee: 20.83,
				description: 'Crédito 15x',
				installments: 15,
				operation_mode: 'credit',
			},
			{
				code: 'credit16x',
				fee: 22,
				description: 'Crédito 16x',
				installments: 16,
				operation_mode: 'credit',
			},
			{
				code: 'credit17x',
				fee: 23.3,
				description: 'Crédito 17x',
				installments: 17,
				operation_mode: 'credit',
			},
			{
				code: 'credit18x',
				fee: 24.56,
				description: 'Crédito 18x',
				installments: 18,
				operation_mode: 'credit',
			},
		],
		type: '',
		payment_method: '',
		merchant_id: '',
		acquirer_id: '',
		settlement_plan_id: '',
		cost_plan_id: '',
	};

	const createSalePlan = async (values, { resetForm }) => {
		setIsLoading(true);

		try {
			const requestParams: CreateSalesPlanRequest = {
				createSalePlanDto: {
					...values,
				},
			};

			await salesPlansApi.createSalesPlan(requestParams);
			addToast('Plano de venda Criado!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao criar Plano de venda', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
			resetForm();
		}
	};

	const getMerchants = async () => {
		setIsLoading(true);

		try {
			const response = await merchantsApi.listMerchants();

			await setMerchants(response);
		} catch (error) {
			addToast('Erro ao buscar Merchant', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getAcquirer = async () => {
		setIsLoading(true);

		try {
			const response = await acquirersApi.listAcquirers();

			await setAcquirers(response);
		} catch (error) {
			addToast('Erro ao buscar planos de venda', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getSettlementPlan = async () => {
		setIsLoading(true);

		try {
			const response = await settlementPlansApi.listSettlementPlans();

			await setSettlementPlans(response);
		} catch (error) {
			addToast('Erro ao buscar planos de venda', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getCostPlans = async () => {
		setIsLoading(true);

		try {
			const response = await costsPlansApi.listAllCostsPlans();

			await setCostPlans(response);
		} catch (error) {
			addToast('Erro ao buscar planos de venda', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		getMerchants();
		getAcquirer();
		getSettlementPlan();
		getCostPlans();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const paymentTypeToRender = Array.from(Array(22).keys());
	const renderPaymentTypes = (errors, values) =>
		paymentTypeToRender.map((i, index) => <PaymentTypeForm errors={errors} index={index} values={values} />);

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink href='/admin/planos-de-venda'>Planos de Venda</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink fontWeight='semibold'>Criar Plano de Venda</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper p={4}>
				<Flex flexDirection='column'>
					<>
						<Formik initialValues={createSalePlanInitialValues} validationSchema={createSalePlanSchema} onSubmit={createSalePlan}>
							{({ handleSubmit, isValid, setFieldValue, errors, values }) => {
								return (
									<form onSubmit={handleSubmit} style={{ width: '100%' }}>
										<Stack px={4} spacing={2}>
											<SalePlanForm
												errors={errors}
												merchants={merchants}
												acquirers={acquirers}
												settlementPlans={settlementPlans}
												costPlans={costPlans}
											/>

											<Heading color='gray.700' as='h6' size='sm' py='1%'>
												Tipos de Pagamento
											</Heading>

											{renderPaymentTypes(errors, values)}
										</Stack>

										<Center pt='6'>
											<Button size='md' disabled={!isValid} type='submit' isLoading={isLoading} className='button__create-sale-plan'>
												Criar Plano de Venda
											</Button>
										</Center>
									</form>
								);
							}}
						</Formik>
					</>
				</Flex>
			</Paper>
		</Flex>
	);
};

export default CreateSalePlan;
